<template>
  <div class="service" v-loading="isLoading">
    <header>
      <div class="back" @click="$router.push({name:'serviceHall'})">
        <svg-icon class="icon" icon-class="pulldown" /> 返回服务大厅
      </div>
      {{title}}
    </header>
    <div class="main-box">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      title: '服务大厅',
      isLoading: false
    }
  },
  created () {
    this.title = this.$route.meta && this.$route.meta.title
  },
  methods: {},

  watch: {
    $route () {
      this.title = this.$route.meta && this.$route.meta.title
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  height: 58px;
  line-height: 58px;
  font-size: 16px;
  color: #303b50;
  letter-spacing: 1px;
  background: #ffffff;
  text-align: center;
  position: relative;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  box-shadow: 0px 4px 8px 0px rgba(45, 50, 54, 0.1);
  .back {
    position: absolute;
    left: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $primaryColor;
    .icon {
      transform: rotate(90deg);
      margin-right: 12px;
    }
  }
}
.service {
  padding-top: 60px;
  background-color: #f5f8fb;
  min-height: 100vh;
  .main-box {
    position: relative;
    min-height: calc(100vh - 60px);
    padding: 0 0 24px;
  }
  ::v-deep.step-content {
    // min-height: calc(100vh - 220px);
    background: #ffffff;
    border: 1px solid #dee2e8;
    // margin-top: 32px;
    // padding: 0 24px;
  }
  ::v-deep.item-title {
    // border-left: 3px $primaryColor solid;
    font-size: 14px;
    font-weight: bold;
    color: #303b50;
    line-height: 19px;
    position: relative;
    border-bottom: 1px #dee2e8 solid;
    padding: 8px 12px 16px;
    margin-bottom: 24px;
    .hint {
      color: #ff443e;
    }
    &::before {
      position: absolute;
      width: 3px;
      height: 16px;
      content: '';
      background: $primaryColor;
      top: 8px;
      left: 0;
    }
  }
}
</style>